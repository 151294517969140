// ScrollToTopButton.js
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { CiCircleChevUp } from "react-icons/ci";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <Button
        onClick={scrollToTop}
        style={styles.button}
        aria-label="Scroll to top"
      >
        <CiCircleChevUp />
      </Button>
    )
  );
}

const styles = {
  button: {
    position: 'fixed',
    bottom: '1.5rem',
    right: '1.5rem',
    fontSize: '2rem',
    color: 'black',
    backgroundColor: 'rgb(249, 230, 158)',
    border: '2px solid black',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: '1000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.3s, transform 0.2s',
    padding: '0',
  },
};

export default ScrollToTopButton;
