import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // للحصول على اسم المستخدم من الرابط
import { auth } from "./Authentication/firebase";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Form, Button, Alert, Container, Spinner } from "react-bootstrap";

function Profile() {
  const { username } = useParams(); // الحصول على اسم المستخدم من معلمة الرابط
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState(""); // حقل السيرة الذاتية الجديد
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null); // لتحميل الملف
  const [isCurrentUser, setIsCurrentUser] = useState(false); // التحقق من إذا كان الشخص يعرض ملفه الشخصي
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const db = getFirestore();
  const storage = getStorage();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      setError(null);

      try {
        // الاستعلام من Firestore للحصول على تفاصيل المستخدم بناءً على اسم المستخدم
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data(); // افتراض أن اسم المستخدم فريد
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setBio(userData.bio || ""); // تعيين السيرة الذاتية إذا كانت موجودة
          setProfilePictureURL(userData.profilePictureURL || null);

          // التحقق مما إذا كان الملف الشخصي ينتمي إلى المستخدم الحالي
          setIsCurrentUser(userData.uid === user?.uid);
        } else {
          setError("المستخدم غير موجود.");
        }
      } catch (err) {
        setError("فشل في جلب بيانات المستخدم.");
      } finally {
      }
    };

    fetchUserData();
  }, [username, db, user]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    setError(null);

    try {
      let profilePictureURLUpdated = profilePictureURL;

      // تحديث صورة الملف الشخصي إذا تم اختيار ملف جديد
      if (profilePicture) {
        const profilePictureRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(profilePictureRef, profilePicture);
        profilePictureURLUpdated = await getDownloadURL(profilePictureRef);
      }

      // تحديث Firestore بالبيانات الجديدة، بما في ذلك السيرة الذاتية
      await updateDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        bio, // إضافة حقل السيرة الذاتية
        profilePictureURL: profilePictureURLUpdated,
      });

      setProfilePictureURL(profilePictureURLUpdated);
      setMessage("تم تحديث الملف الشخصي بنجاح!");
    } catch (err) {
      setError("فشل في تحديث الملف الشخصي. يرجى المحاولة مرة أخرى.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px" }}>
      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <>
          <h2 className="text-center">{isCurrentUser ? "تعديل الملف الشخصي" : firstName + " " + lastName}</h2>
          {profilePictureURL && (
            <div className="text-center mb-3">
              <img
                src={profilePictureURL}
                alt="Profile"
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              />
            </div>
          )}

          {isCurrentUser ? (
            <Form onSubmit={handleProfileUpdate}>
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>الاسم الأول</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="أدخل الاسم الأول"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Label>الاسم الأخير</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="أدخل الاسم الأخير"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBio" className="mb-3">
                <Form.Label>السيرة الذاتية</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="أدخل سيرتك الذاتية"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formProfilePicture" className="mb-3">
                <Form.Label>صورة الملف الشخصي</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setProfilePicture(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    جاري التحديث...
                  </>
                ) : (
                  "تحديث الملف الشخصي"
                )}
              </Button>
            </Form>
          ) : (
            <div>
              <p>{bio || "لا توجد سيرة ذاتية."}</p>
            </div>
          )}
          {message && <Alert variant="success" className="mt-3">{message}</Alert>}
        </>
      )}
    </Container>
  );
}

export default Profile;
