import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="text-center">من نحن</h1>
                    <p className="text-center mt-3">
                        مرحباً بكم في <strong className="site-title">Soumar Tech Park</strong>
                    </p>
                    <p>
                        في <strong className="site-title">Soumar Tech Park</strong>، نحن شغوفون بالتكنولوجيا وكل ما يتعلق بها. مهمتنا هي تقديم محتوى مميز لمحبي التقنية، أحدث الأخبار، المراجعات، والتحليلات التي تساعد في فهم التطورات التقنية الحديثة. سواء كنت محترفاً في مجال التقنية أو هاوٍ، نحن نسعى لتغطية مجموعة واسعة من المواضيع التي تناسب جميع المستويات.
                    </p>
                    <p>
                        تأسسنا في عام <strong>2021</strong>، وأصبح موقعنا بسرعة مركزاً لمحبي التكنولوجيا لاستكشاف أحدث الاتجاهات، ومتابعة الابتكارات، والتواصل مع مجتمع التقنيين. نحن نؤمن بإنشاء بيئة تعليمية وتفاعلية يمكن للجميع فيها مشاركة اهتماماتهم التقنية.
                    </p>
                    <p>
                        لا تتردد في تصفح مقالاتنا. نحن نقدر دعمكم ونتطلع إلى مشاركة رحلتنا في عالم التكنولوجيا معكم!
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default About;
