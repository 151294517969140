import React, { useState } from "react";
import { auth } from "./firebase"; // Import the auth instance from Firebase
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, doc, setDoc } from "firebase/firestore"; // Firestore functions
import { Form, Button, Alert, Container, Spinner } from "react-bootstrap";

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState(""); // New username state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true); // Start loading

    const db = getFirestore();

    try {
      // Check if the username is already taken
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setError("اسم المستخدم موجود بالفعل. الرجاء اختيار اسم مختلف.");
        setLoading(false);
        return;
      }

      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // After user is created, save additional details to Firestore
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        username, // Include username
        email,
        uid: user.uid
      });

      setMessage("تم إنشاء الحساب بنجاح!");
    } catch (error) {
      setError("حدث خطأ: " + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px" }}>
      <h2 className="text-center">التسجيل</h2>
      <Form onSubmit={handleSignup}>
        <Form.Group controlId="formFirstName" className="mb-3">
          <Form.Label>الاسم الأول</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل الاسم الأول"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formLastName" className="mb-3">
          <Form.Label>لقب العائلة</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل لقب العائلة"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formUsername" className="mb-3">
          <Form.Label>اسم المستخدم</Form.Label>
          <Form.Control
            type="text"
            placeholder="أدخل اسم المستخدم"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>البريد الالكتروني</Form.Label>
          <Form.Control
            type="email"
            placeholder="أدخل البريد الإلكتروني"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" className="mb-3">
          <Form.Label>كلمة السر</Form.Label>
          <Form.Control
            type="password"
            placeholder="أدخل كلمة السر"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100" disabled={loading}>
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              جاري إنشاء الحساب...
            </>
          ) : (
            "انشاء حساب"
          )}
        </Button>
      </Form>
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      {message && <Alert variant="success" className="mt-3">{message}</Alert>}
    </Container>
  );
}

export default Signup;
