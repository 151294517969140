import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';

function Terms() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="text-center">الشروط والأحكام</h1>
                    <p className="text-center mt-3">آخر تحديث: <strong>10 نوفمبر 2024</strong></p>

                    <p>
                        مرحباً بكم في <strong className="site-title">Soumar Tech Park</strong>! باستخدامك أو وصولك إلى مدونتنا، فإنك توافق على الالتزام بالشروط والأحكام التالية. إذا كنت لا توافق على هذه الشروط، يرجى عدم استخدام موقعنا.
                    </p>

                    <h4>استخدام الموقع</h4>
                    <p>
                        يمكنك استخدام مدونتنا لأغراض شخصية وغير تجارية فقط. لا يجوز لك إعادة إنتاج أو توزيع أو إنشاء أعمال مشتقة من أي محتوى بدون موافقتنا الخطية.
                    </p>

                    <h4>المحتوى</h4>
                    <p>
                        جميع المحتويات المقدمة على <strong>Soumar Tech Park</strong> هي لأغراض إعلامية فقط. نحن لا نضمن دقة أو اكتمال أو فائدة أي معلومات على هذه المدونة.
                    </p>

                    <h4>الروابط الخارجية</h4>
                    <p>
                        قد تحتوي مدونتنا على روابط لمواقع ويب تابعة لجهات خارجية. نحن لسنا مسؤولين عن محتوى أو ممارسات هذه المواقع.
                    </p>

                    <h4>إخلاء المسؤولية</h4>
                    <p>
                        <strong>Soumar Tech Park</strong> غير مسؤول عن أي أضرار ناتجة عن استخدام مدونتنا أو المعلومات الواردة فيها.
                    </p>

                    <h4>تغييرات على الشروط</h4>
                    <p>
                        نحتفظ بالحق في تعديل هذه الشروط في أي وقت. يُعتبر استمرار استخدام المدونة بعد التغييرات قبولاً للشروط الجديدة.
                    </p>

                    <h4>القانون الحاكم</h4>
                    <p>
                        تحكم هذه الشروط وفقاً لقوانين <strong>كيبيك، كندا</strong>.
                    </p>

                    <h4>اتصل بنا</h4>
                    <p> 
                        إذا كانت لديك أي أسئلة حول هذه الشروط، يرجى <strong><a href="/contact">الاتصال بنا</a></strong>.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Terms;
