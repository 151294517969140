import React from 'react';
import { Container, Navbar, Image, Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsTwitterX } from 'react-icons/bs';
import { FiSun, FiMoon } from 'react-icons/fi';

function UpperNavbar({ darkMode, toggleDarkMode }) {

  return (
    <Navbar className="bg-body-tertiary">
      <Container className="d-flex justify-content-between">
        <Navbar.Brand as={Link} to="/">
          <Image src="../logo.png" width="60" height="60" />
        </Navbar.Brand>

        <Nav className="d-flex align-items-center">
          <Nav.Item>
            <Button
              as="button"
              variant="link"
              onClick={toggleDarkMode}
              className="navbar-icon"
              aria-label="Toggle dark mode"
            >
              {darkMode ? <FiSun /> : <FiMoon />}
            </Button>
          </Nav.Item>

          <Nav.Item>
            <Button
              as="a"
              href="https://x.com/STechpark62878"
              target="_blank"
              rel="noopener noreferrer"
              variant="link"
              className="navbar-icon"
            >
              <BsTwitterX />
            </Button>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default UpperNavbar;