import React from 'react';
import { Carousel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FeaturedCarousel = ({ featuredPosts }) => {
    return (
        featuredPosts.length > 0 && (
            <Row>
                <Carousel>
                    {featuredPosts.map((post) => (
                        <Carousel.Item key={post.id}>
                            <Link to={`/articles/${post.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <img
                                    className="d-block w-100"
                                    src={post.imageUrl}
                                    alt={post.title}
                                />
                                <Carousel.Caption className="carousel-caption">
                                    <h3 className='carousel-title'>{post.title}</h3>
                                </Carousel.Caption>
                            </Link>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
        )
    );
};

export default FeaturedCarousel;
