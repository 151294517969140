import { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // Import styles

NProgress.configure({ showSpinner: false }); // Disable the spinner

const TopLoader = ({ isLoading }) => {
  useEffect(() => {
    if (isLoading) {
      NProgress.start(); // Start loading bar
    } else {
      NProgress.done(); // Finish loading bar
    }
  }, [isLoading]);

  return null; // This component doesn't render anything visible itself
};

export default TopLoader;
