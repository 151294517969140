import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./Authentication/firebase";
import { Alert } from "react-bootstrap";

const Messages = ({ setIsLoading }) => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);

  // Helper function to format the date
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // For AM/PM format
    }).format(date);
  };

  useEffect(() => {
    setIsLoading(true); // Start loading
    const fetchMessages = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "messages"));
        const messageData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messageData);
      } catch (err) {
        setError("Failed to load messages.");
        console.error("Error fetching messages from Firestore:", err);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    fetchMessages();
  }, [setIsLoading]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="message-list">
      <h3>رسائل الزوار</h3>
      {messages.length === 0 ? (
        <Alert variant="info">لا وحود لرسائل.</Alert>
      ) : (
        <div>
          {messages.map((message) => (
            <div key={message.id} className="p-2">
              <h5>{message.name}</h5>
              <p><strong>البريد الالكتروني:</strong> {message.email}</p>
              <p><strong>الرسالة:</strong> {message.message}</p>
              {message.date && ( // Only display the date if it exists
                <p>
                  <strong>تاريخ الارسال:</strong> {formatDate(message.date)}
                </p>
              )}
              <hr />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Messages;
