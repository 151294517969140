import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Row, Col, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from './Authentication/firebase';
import { useCallback } from 'react';

const EditArticlePage = ({ setIsLoading }) => {
     const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['link', 'image'],
            ['clean'],
            [{'color':[]}],
        ],
    };

    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'align',
        'link',
        'image',
        'color'
    ];

    const { slug } = useParams();
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [content, setContent] = useState('');
    const [comment, setComment] = useState('');
    const [commentDetails, setCommentDetails] = useState('');
    const [category, setCategory] = useState('');
    const [score, setScore] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());
    const [image, setImage] = useState(null);
    const [featured, setFeatured] = useState(false);
    const [isShowWriter, setIsShowWriter] = useState(false);
    const [existingImage, setExistingImage] = useState('');
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState(null);

    const fetchArticle = useCallback(async () => {
        try {
            setIsLoading(true);
            if (!slug) {
                setError('لم يتم تقديم الرابط الثابت في عنوان URL.');
                return;
            }

            const articlesRef = collection(firestore, 'articles');
            const q = query(articlesRef, where('slug', '==', slug));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const articleData = querySnapshot.docs[0].data();
                setId(querySnapshot.docs[0].id);
                setTitle(articleData.title);
                setIntro(articleData.intro);
                setContent(articleData.content);
                setComment(articleData.comment);
                setCommentDetails(articleData.commentDetails);
                setCategory(articleData.category);
                setFeatured(articleData.featured);
                setIsShowWriter(articleData.isShowWriter);
                const formattedDate = new Date(articleData.createdAt).toISOString().split('T')[0];
                setCreatedAt(formattedDate);
                setExistingImage(articleData.imageUrl);

                if (articleData.category === 'Reviews') {
                    setScore(articleData.score || '');
                }
            } else {
                setError('لم يتم العثور على المقال.');
            }
        } catch (err) {
            setError(`حدث خطأ أثناء جلب المقال: ${err.message}`);
        } finally {
            setIsLoading(false);
        }
    }, [slug, setIsLoading]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArticle();
    }, [slug, fetchArticle, setIsLoading]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title.trim() || !intro.trim() || !content.trim() || !category) {
            setError('يرجى ملء جميع الحقول المطلوبة.');
            return;
        }
        if (category === 'Reviews' && (!score || score < 0 || score > 10)) {
            setError('يجب أن يكون التقييم بين 0 و 10.');
            return;
        }

        setError(null);
        setUploading(true);
        setMessage(null);

        try {
            const articleRef = doc(firestore, 'articles', id);
            let imageUrl = existingImage;

            if (image) {
                const imageRef = ref(storage, `images/${image.name}`);
                const uploadTask = uploadBytesResumable(imageRef, image);
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        null,
                        reject,
                        async () => {
                            imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve();
                        }
                    );
                });
            }

            await updateDoc(articleRef, {
                title,
                intro,
                content,
                comment,
                commentDetails,
                featured,
                isShowWriter,
                category,
                imageUrl,
                createdAt,
                ...(category === 'Reviews' && { score }),
            });

            setUploading(false);
            setMessage('تم تحديث المقال بنجاح!');
            navigate(`/articles/${slug}`);
        } catch (err) {
            setError(`فشل في تحديث المقال: ${err.message}`);
            setUploading(false);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setImage(e.target.files[0]);
        }
    };

    return (
        <Container dir="rtl">
            <h1 className="my-4 text-center">تعديل مقال</h1>

            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}

            <Form onSubmit={handleSubmit}> 
                <Row>
                    <Col>
                        <Form.Group controlId="formTitle">
                            <Form.Label>العنوان *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="اكتب العنوان هنا..."
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Form.Group controlId="formIntro">
                            <Form.Label>المقدمة *</Form.Label>
                             <Form.Control
                                as="textarea"
                                placeholder="اكتب المقدمة هنا..."
                                value={intro}
                                onChange={(e) => setIntro(e.target.value)}
                                style={{ height: '100px' }}
                                />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={6}>
                        <Form.Group controlId="formCategory">
                            <Form.Label>تصنيف *</Form.Label>
                            <Form.Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="">اختيار تصنيف</option>
                                <option value="News">أخبار</option>
                                <option value="Reports">تقارير</option>
                                <option value="Reviews">مراجعات</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formCreatedAt">
                            <Form.Label>تاريخ الإنشاء</Form.Label>
                            <Form.Control
                                type="date"
                                value={createdAt}
                                onChange={(e) => setCreatedAt(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {category === 'Reviews' && (
                    <>
                        <Form.Group controlId="formComment" className="mt-3">
                            <Form.Label>عنوان التعليق</Form.Label>
                            <Form.Control
                                type="text"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCommentDetails" className="mt-3">
                            <Form.Label>التعليق</Form.Label>
                            <Form.Control
                                type="text"
                                value={commentDetails}
                                onChange={(e) => setCommentDetails(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formScore" className="mt-3">
                            <Form.Label>التقييم (0-10)</Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                max="10"
                                value={score}
                                onChange={(e) => setScore(e.target.value)}
                            />
                        </Form.Group>
                    </>
                )}

                <Form.Group controlId="formContent" className="mt-3">
                    <Form.Label>المحتوى *</Form.Label>
                    <ReactQuill
                      value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        placeholder="اكتب مقالك هنا..."
                    />
                </Form.Group>

                <Form.Group controlId="formImage" className="mt-3">
                    <Form.Label>تحميل صورة جديدة (اختياري)</Form.Label>
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </Form.Group>

                {existingImage && (
                    <div className="mt-3">
                        <p>الصورة الحالية:</p>
                        <img src={existingImage} alt="Current" style={{ maxWidth: '200px' }} />
                    </div>
                )}

                <Form.Group controlId="formFeatured" className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="تمييز المقال"
                        checked={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                    />
                </Form.Group>

                <Form.Group controlId="formIsShowWriter" className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="إظهار اسم الكاتب"
                        checked={isShowWriter}
                        onChange={(e) => setIsShowWriter(e.target.checked)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-4" disabled={uploading}>
                    {uploading ? (
                        <>
                            <Spinner className="custom-spinner" animation="grow" size="sm" /> يتم التحميل...
                        </>
                    ) : (
                        'تحديث المقال'
                    )}
                </Button>
            </Form>
        </Container>
    );
};

export default EditArticlePage;
