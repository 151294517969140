import React, { useEffect, useState, useContext } from 'react';
import { Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

import { DarkModeContext } from './DarkModeContext';

const ArticlesList = ({ setIsLoading }) => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [error, setError] = useState(null);
    const { darkMode } = useContext(DarkModeContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'articles'));
                const articles = [];
                querySnapshot.forEach((docSnap) => {
                    articles.push({ ...docSnap.data(), id: docSnap.id });
                });
                setBlogPosts(articles);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching posts:', err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPosts();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this article?')) {
            try {
                await deleteDoc(doc(firestore, 'articles', id));
                setBlogPosts(blogPosts.filter(post => post.id !== id));
            } catch (err) {
                setError(err.message);
                console.error('Error deleting post:', err);
            }
        }
    };

    const categoryMap = {
        News: "أخبار",
        Reports: "تقارير",
        Reviews: "مراجعات"
    };

    const getCategory = (category) => categoryMap[category] || category;

    const columnDefs = [
        { headerName: 'العنوان', field: 'title', flex: 2, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } },
        { headerName: 'تاريخ النشر', field: 'createdAt', flex: 1},
        { headerName: 'التصنيف', field: 'category', flex: 1, valueGetter: (params) => getCategory(params.data.category) },
        {
            headerName: '',
            field: 'id',
            width: 250,
            cellRenderer: (params) => (
                <div>
                    <Link to={`/articles/${params.data.slug}`} className="me-2">
                        <Button size='sm' variant="light">
                            <FaEye />
                        </Button>
                    </Link>
                    <Link to={`/edit/${params.data.slug}`} className="me-2">
                        <Button size='sm' variant="light">
                            <FaEdit />
                        </Button>
                    </Link>
                    <Button size='sm' variant="danger" onClick={() => handleDelete(params.data.id)} className="me-2">
                        <FaTrash />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Container>
            <h1 className="my-4">قائمة المقالات</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <div 
                className="ag-theme-material" 
                style={{ 
                    height: 1000, 
                    width: '100%', 
                    filter: darkMode ? 'invert(1) hue-rotate(180deg)' : 'none',
                    background: darkMode ? '#2e2e2e' : 'white',
                    color: darkMode ? '#ffffff' : '#000000',
                }}
            >
                <AgGridReact
                    rowData={blogPosts}
                    columnDefs={columnDefs}
                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                    pagination={true}
                    paginationPageSize={30}
                />
            </div>
        </Container>
    );
};

export default ArticlesList;
