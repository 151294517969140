import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import AppNavbar from './AppNavbar';
import UpperNavbar from './UpperNavbar';
import { Container } from 'react-bootstrap';
import { DarkModeContext } from './DarkModeContext';

function Layout({ children }) {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('dark-mode') ? localStorage.getItem('dark-mode') === 'true' : true;
    setDarkMode(savedDarkMode);
    document.body.classList.toggle('dark', savedDarkMode);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.body.classList.toggle('dark', newMode);
      localStorage.setItem('dark-mode', newMode);
      return newMode;
    });
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <div data-bs-theme={darkMode ? 'dark' : 'light'}>
        <UpperNavbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <AppNavbar darkMode={darkMode} />
        <Container className="mt-4 app-content">
          <main>{children}</main>
        </Container>
        <Footer />
      </div>
    </DarkModeContext.Provider>
  );
}

export default Layout;